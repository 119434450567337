<template lang="pug">
div.view_container
  Header(v-on:is-small-screen="setIsSmallScreen")
  div.view_title Thanks!
  div.description
    span Check your email for confirmation.  It may be in your SPAM folder.
    br
    span But, we’ll take it from here.  Your email invites are going out and we’ll update you whenever someone does their video.
    br
    br
    span If you have time, we encourage you to do your videos.
  br
  br
  button.cta(v-on:click="next('/add_video')") Do your video
  button(v-if="currentProject.shareUrl" v-on:click="copyShareUrl(currentProject.shareUrl)") Share on social media
  button(v-on:click="next('/')" ) Go home
  input#input_field_to_copy(type="text")
</template>

<script>
import { INVITE_PEOPLE_MUTATION } from '../graphql/mutations'
import Header from '../components/Header'
import { clearSelection } from '../lib/utils'

export default {
  name: 'Thanks',
  props: {

  },
  components: {
    Header,
  },
  computed: {
    currentProject () {
      return this.$store.state.currentProject
    },
    currentUser () {
      return this.$store.state.user.user
    },
  },
  data () {
    return {
      isSmallScreen: false,
      loading: false,
    }
  },
  apollo: {

  },
  methods: {
     setIsSmallScreen: function(isSmall) {
      this.isSmallScreen = isSmall
    },
    copyShareUrl: function(url) {
      var copyText = document.getElementById("input_field_to_copy");

      copyText.value = url

      /* Select the text field */
      copyText.select()
      copyText.setSelectionRange(0, 99999) /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy")
      clearSelection()
      this.$toast.success("Your share link is copied to the clipboard. Just go to your social media app and paste it in a post.", {timeout: 7000})
    },
    next: function (name) {
      if (name.match(/create/)) { this.$store.commit('clearProject') }
      this.$router.push(name)
    },
    invitePerson: async function() {
      this.loading = true
      this.$apollo.mutate({
        mutation: INVITE_PEOPLE_MUTATION,
        variables: {
          projectId: this.currentProject.id,
          participants: [{
            name: this.name,
            email: this.email
          }]
        },
      }).then((data) => {
        this.loading = false
        console.log(data)
        this.name = null
        this.email = null
      }).catch((error) => {
        this.loading = false
        console.error(error)
      })
    },
  },
  mounted: function() {
    window.vue = this
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.title {
  font-family: gibsonsemibold;
  font-size: 26px;
  color: #151516;
  margin-bottom: 10px;
}
.description {
  font-family: gibsonregular;
  font-size: 20px;
  color: #79787D;
}

#input_field_to_copy {
  position:absolute;
  z-index: 1;
  top: -100px;
  left: 0px;
  opacity: 0;
}

button.cta {
  display: block;
  font-size: 20px;
}

button {
  display: block;
  font-size: 20px;
  margin: 0px 0px 20px 0px;
  padding: 20px 44px;
  border-radius: 35px;
  width: 300px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
}

</style>
